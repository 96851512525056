<template>
  <div class="fundraisesAll_main" v-loading="loadings"  element-loading-text="加載内容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="fundraisesAll_content">
        <div class="variety" v-if="categorys.length > 0">
        <h4 style="color: #166e71;">分類</h4>
        <div class="classifition" v-for="count in categorys_count" :key="count">
            <template v-for="(category,index) in categorys" :key="category">
            <!-- <router-link to='#'>123</router-link> -->
                <router-link to='#' v-if="index >= ((count * 5) - 5) && index <= (count * 5) - 1" :style="[{'color':categorys_id == category.id?'red':''}]" v-on:click="paging_name(category.id,category.name)">{{category.name}}</router-link>
            </template>
            
        </div>
    </div>
    <div class="variety" style="margin-top: 15px;text-align: center;" v-else>
        <h4 style="color: red;">{{category_title}}</h4>
    </div>
    <div class="search_mores">
        <!-- <img src="../../../assets/img/uparrow.png" v-on:click = "search">
        <p style="margin-top:10px;color:#166e71;cursor:pointer;" v-on:click = "search" >返回</p> -->
        <div style="width:100%;height:10px;"></div>
        <div v-if="search_page_name && indexs_a.length === 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">暫時找不到類別為&nbsp;<span style="color:red">{{search_page_name}}</span>&nbsp;結果</div>
        <div v-else-if="search_page_name && indexs_a.length !== 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">{{search_page_name}}</div>
        <div v-else-if="keyword && indexs_a.length !== 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">搜索&nbsp;<span style="color:red">“{{keyword}}”</span>&nbsp;結果</div>
        <div v-else-if="keyword && indexs_a.length === 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">暫時找不到&nbsp;<span style="color:red">“{{keyword}}”</span>&nbsp;結果</div>
        <!-- <div v-else-if="indexs_a.length !== 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">搜索更多</div> -->
        <div v-else-if="indexs_a.length == 0" style="text-align:center;margin-top:30px;font-size:30px;color: #166e71;">暫時找不到結果</div>
        <div class="status_btn_list" style="text-align:right;">
            <a href="javascript:;" class="status_btn" @click="active_click(1)" :class="active">正在籌款</a> <a href="javascript:;" class="status_btn" @click="active_click(2)" :class="actives">已結束</a>
        </div>
    </div>
    <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);" v-for="counts in count" :key="counts">
       <template v-for="(item,index) in indexs_a" :key="index">
            <div v-if="index >= ((counts * 4) - 4) && index <= (counts * 4) - 1" class="swiper-slide swiper-slide-active" style="border: 1px solid #cccccc;float:right;cursor:pointer;">
            <router-link class="swiper-slide-a" :to="{name:'project',params: { id: item.uuid }}">
                <div class="swiper-slide-bg">
                    <div class="image">
                        <img :src="fileUrl(item.project.image)" />
                    </div>
                    
                    <div class="swiper-slide-content">
                        
                        <div class="organization">
                            <img :src="fileUrl(item.agency.avatar)" style="width: 50px;">
                            <div class="organ">
                                <span>{{item.agency.name}}</span>
                                <p>已發起{{item.agency.fundraisesCount}}個項目 · {{item.agency.totalNumberDonations}}個支持者</p>
                            </div>
                            
                        </div>
                        <div class="title">
                            <a href="/" class="">{{item.project.title}}</a>
                            <p>已籌 {{ item.fundraiseAmount }}/{{ item.amountRequired }}</p>
                        </div>
                        <div class="progress-container">
                            <div class="progress" :style="{ '--percent': percentage(item) }"></div>
                        </div>
                        <div class="people_number">
                            <p>捐款人數: {{ item.numberDonations }}</p><p v-if="item.state ==confirmation_state">籌款中</p><p v-else-if="item.state ==finish_state">已完結</p>
                        </div>
                    </div>
                </div>
                <img :src="fileUrl(item.project.image)" style="height: 180px; width: 100%; border-radius: 10px;">
                
            </router-link>
            </div>
        </template>
       </div>
        <div class="page-bar" v-if="(search_page_name && indexs_a.length > 0) || (indexs_a.length > 0)" v-loading="loadings"  element-loading-text="加載内容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
             <ul>
            <li v-if="cur>1"><a v-on:click="pageClick('top')">上一頁</a></li>
            <li v-if="cur==1"><a class="banclick">上一頁</a></li>
            <li v-for="index in indexs" :key="index" v-bind:class="{ 'active': cur == index}">
            <a v-on:click="btnClick(index)">{{ index }}</a>
            </li>
            <li v-if="cur!=all"><a v-on:click="pageClick('down')">下一頁</a></li>
            <li v-if="cur == all"><a class="banclick">下一頁</a></li>
            <li><a>共<i>{{all}}</i>頁</a></li>
            </ul>
        </div>
    </div>
    
  </div>
</template>
<style>
.classifition{
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
}
.classifition a{
  display: block;
  width: 85px;
  height: 20px;
  line-height: 20px;
  color: grey;
  text-decoration: none;
}
.classifition a:hover{
  color: #14716d;
}
</style>
<script>
import api from "@/api";
export default {
  data() {
    return {
        active:'active',
        actives:'',
        all: 0, //总页数
        cur: 1,//当前页码
        indexs_a:[],
        count:0,
        state: '50010',
        confirmation_state: '50010',
        finish_state: '50020',
        pageSize:4,
        search_page_name:'',
        loadings:false,
        keyword:"",
        categorys: [],
        categorys_count:0,
        category_title:'',
        categorys_id:0,
    };
  },
  methods:{
      percentage: function (data) {
          return parseInt((100 * data.fundraiseAmount) / data.amountRequired) <= 100 ? parseInt((100 * data.fundraiseAmount) / data.amountRequired) + "px" : '100px';
      },
       // 選擇分類篩選出該類別下的所有衆籌項目
    paging_name(val,name){
      // 選擇類型 選擇那種類型就篩選那種類型數據 如果點擊已經選擇了的類別則會篩選出全部數據
      // 选择分类时将关键字内容清空
      this.explore = '';
    //   this.$store.state.keyword = "";
      if(this.categorys_id === val){
        this.$store.state.search_page_id = 0;
        this.$store.state.search_page_name = '';
        this.categorys_id = 0;
      }else{
        this.categorys_id = val;
        this.$store.state.search_page_id = val;
        this.$store.state.search_page_name = name;
      }
    },
    fileUrl(file) {
      return process.env.VUE_APP_SERVER +file;
    },
    keyword_s(){
        let keyword_s = this.$store.state.keyword;
        this.loadings = true;
        this.search_page_name = "";
        this.cur = 1;
        // 顯示分頁數據
        this.$store.state.search = true;
        // 默認值為空時查詢全部
        if(keyword_s === "keyword123456789"){
            this.keyword = '';
            this.$store.state.keyword = "";
            api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state).then(response => {
                if(response.code == 200){
                    let results = response.data.results;
                    this.indexs_a = results;
                    this.count = Math.ceil(results.length / 4);
                    this.all = Math.ceil(response.data.count / this.pageSize);
                }else{
                    this.count = 0;
                    this.indexs_a = [];
                    this.all = 0;
                    this.cur = 1;
                }
                this.loadings = false;
            });
        }else{
            this.keyword = keyword_s;
            api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state+'&search_title='+keyword_s).then(response => {
                if(response.code == 200){
                    let results = response.data.results;
                    this.indexs_a = results;
                    this.count = Math.ceil(results.length / 4);
                    this.all = Math.ceil(response.data.count / this.pageSize);
                }else{
                    this.count = 0;
                    this.indexs_a = [];
                    this.all = 0;
                    this.cur = 1;
                }
                this.loadings = false;
            });
        }
    },
    search(){
        this.$store.state.search = false;
    },
    fundraisePage(){
        this.loadings = true;
        // 判斷是否有選擇了的類型
        let category_id = this.$store.state.search_page_id;
        if(this.$store.state.keyword){
            this.keyword_s();
        }else if(category_id === 0 || category_id == -1){
            api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state).then(response => {
                if(response.code == 200){
                    let results = response.data.results;
                    this.indexs_a = results;
                    this.count = Math.ceil(results.length / 4);
                    this.all = Math.ceil(response.data.count / this.pageSize);
                }else{
                    this.count = 0;
                    this.indexs_a = [];
                    this.all = 0;
                    this.cur = 1;
                }
                this.loadings = false;
            });
        }else{
            this.$store.state.search = true;
            api.fundraise.getCategorysFundraise('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state+'&category='+category_id).then(response => {
                if(response.code == 200){
                    let results = response.data.results;
                    this.indexs_a = results;
                    this.count = Math.ceil(results.length / 4);
                    this.all = Math.ceil(response.data.count / this.pageSize);
                }else{
                    this.count = 0;
                    this.indexs_a = [];
                    this.all = 0;
                    this.cur = 1;
                }
                this.loadings = false;
            });
        }
    },
    active_click(val){
        if(val === 1){
            if(!this.active){
                this.active = 'active';
                this.state = this.confirmation_state;
                this.fundraisePage();
            }
            this.actives = '';
        }else{
            if(!this.actives){
                this.actives = 'active';
                this.state = this.finish_state;
                this.fundraisePage();
            }
            this.active = '';
        }
    },
      //分页
    btnClick: function(data){//页码点击事件
        if(data != this.cur){
            this.cur = data
        }
    },
    pageClick: function(val){
        //根据点击页数请求数据
        if(val == 'top'){
            if(this.cur > 1){
                this.cur -= 1;
                this.fundraisePage();
            }
        }else if(val == 'down'){
             if(this.all > this.cur){
                this.cur += 1;
                this.fundraisePage();
            }
        }
    }
  },
  computed: {
    cur: function(){
        return this.cur;
    },
    monitor_search: function (){
      return this.$store.state.monitor_search;
    },
    categorys_fundraise(){
        return this.$store.state.categorys_fundraise;
    },
    search_page_id(){
        return this.$store.state.search_page_id;
    },
    keywords(){
        return this.$store.state.keyword;
    },
//分页
    indexs: function(){
        var left = 1;
        var right = this.all;
        var ar = [];
        while (left <= right){
            ar.push(left)
            left ++
        }
        return ar
    },
},
    watch:{
        keywords(){
            this.categorys_id = 0;
            this.keyword_s();
        },
        search_page_id(val){
            // 如果值为-1则进行终止动作
            // 顯示分頁數據
            this.$store.state.search = true;
            // 搜索关键字时关键字
            // 当选择分类时 再对关键字进行搜索 就会将全局分类值search_page_id 赋值为0 这个时候可能会触发监听进入到该监听方法里面
            if(val && val != -1){
                this.loadings = true;
                // 分类名字
                this.search_page_name = this.$store.state.search_page_name;
                // val 為0 時代表連續點擊了兩下同一個分類 回到搜索全部
                if(val === 0){
                    this.cur = 1;
                    api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state).then(response => {
                        if(response.code == 200){
                            let results = response.data.results;
                            this.indexs_a = results;
                            this.count = Math.ceil(results.length / 4);
                            this.all = Math.ceil(response.data.count / this.pageSize);
                        }else{
                            this.count = 0;
                            this.indexs_a = [];
                            this.all = 0;
                            this.cur = 1;
                        }
                        this.loadings = false;
                    });
                }else{
                    api.fundraise.getCategorysFundraise('?pageNum=1&pageSize='+this.pageSize+'&state='+this.state+'&category='+val).then(response => {
                        if(response.code == 200){
                            let results = response.data.results;
                            this.indexs_a = results;
                            this.count = Math.ceil(results.length / 4);
                            this.all = Math.ceil(response.data.count / this.pageSize);
                        }else{
                            this.count = 0;
                            this.indexs_a = [];
                            this.all = 0;
                            this.cur = 1;
                        }
                        this.loadings = false;
                    });
                }
            }else{
                this.loadings = true;
                this.search_page_name = "";
                this.cur = 1;
                this.keyword = this.$store.state.keyword;
                this.categorys_id = 0;

                if(this.keyword === "keyword123456789"){
                    this.keyword = '';
                    this.$store.state.keyword = "";
                    api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state).then(response => {
                    if(response.code == 200){
                        let results = response.data.results;
                        this.indexs_a = results;
                        this.count = Math.ceil(results.length / 4);
                        this.all = Math.ceil(response.data.count / this.pageSize);
                    }else{
                        this.count = 0;
                        this.indexs_a = [];
                        this.all = 0;
                        this.cur = 1;
                    }
                    this.loadings = false;
                });
                }else{
                    api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state+'&search_title='+this.keyword).then(response => {
                    if(response.code == 200){
                        let results = response.data.results;
                        this.indexs_a = results;
                        this.count = Math.ceil(results.length / 4);
                        this.all = Math.ceil(response.data.count / this.pageSize);
                    }else{
                        this.count = 0;
                        this.indexs_a = [];
                        this.all = 0;
                        this.cur = 1;
                    }
                    this.loadings = false;
                });
                }
            }
        },
        categorys_fundraise(val){
            this.loadings = true;
            api.fundraise.getFundraisePage('?pageNum='+this.cur+'&pageSize='+this.pageSize+'&state='+this.state).then(response => {
                if(response.code == 200){
                    let results = response.data.results;
                    this.indexs_a = results;
                    this.count = Math.ceil(results.length / 4);
                    this.all = Math.ceil(response.data.count / this.pageSize);
                }else{
                    this.count = 0;
                    this.indexs_a = [];
                    this.all = 0;
                    this.cur = 1;
                }
                this.loadings = false;
            });
        },
        // 監聽點擊搜索更多操作
        monitor_search(){
             this.fundraisePage();
        },
        cur(){
            this.fundraisePage();
        }
    },
  created:function(){
      this.fundraisePage();

      api.category.getCategorys().then(response => {
      // this.categorys = response.data.results;
      // 只取出有众筹项目的分类
      let array = [];
      for(let i=0; i<response.data.results.length; i++){
        if(response.data.results[i].fundraisesCount > 0){
          array.push(response.data.results[i]);
        }
      }
      this.categorys = array;

      this.categorys_count = Math.ceil((response.data.results.length / 5));
      if(response.data.count == 0){
        this.category_title = '沒有分類數據！';
      }
      this.loading_explore = false;
    }).catch(error => {
      this.categorys_count = 0;
      this.$message.error('分類數據獲取異常，請重新刷新獲取');
      this.category_title = '分類數據獲取異常，請重新刷新獲取！';
      this.loading_explore = false;
    });
  },
};
</script>


<style lang="scss" scoped>
.fundraisesAll_main {
    margin-top: 20px;
    background: #fff;
    .fundraisesAll_content {
        width: 80%;
        margin: 0 auto;
    }
}
a{ text-decoration:none; }
.status_btn_list {
    
    .status_btn {
        display: inline-block;
        color: #76787a;
        border-bottom: 2px solid transparent;
        padding-bottom: 5px;
        font-size: 16px;
        &.active{
            // border-bottom-color: var(--theme-color);
            border-bottom-color: #E96463;
            font-weight: 600;
            color: #E96463;
        }
    }
}
.swiper-wrapper {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
}
.search_mores{
//   width: 80vw;
width: 100%;
  margin: 10px auto;
  text-align: center;
  margin-top: -40px;
  color: #166e71;
  font-size: 20px;
}
.search_mores img{
  display: block;
  margin: 0 auto;
  width:25px;
}
/*分页*/
.page-bar{
margin:40px auto;
height:10px;
// margin-left:40px;
}
ul,li{
margin: 0px;
padding: 0px;
}
li{
list-style: none
}
.page-bar li:first-child>a {
margin-left: 0px
}
.page-bar a{
border: 1px solid #ddd;
text-decoration: none;
position: relative;
float: left;
padding: 6px 12px;
margin-left: -1px;
line-height: 1.42857143;
color: #5D6062;
cursor: pointer;
margin-right: 20px;
}
.page-bar a:hover{
background-color: #eee;
}
.page-bar a.banclick{
cursor:not-allowed;
}
.page-bar .active a{
color: #fff;
cursor: default;
background-color: #E96463;
border-color: #E96463;
}
.page-bar i{
font-style:normal;
color: #d44950;
margin: 0px 4px;
font-size: 12px;
}

.variety{
  color: #14716d;
  text-align: left;
}
.variety h4{
  padding-left: 10px;
  font-weight: bold;
  font-size: 36px;
  padding-top: 20px;
}
.search_more{
  width: 80vw;
  margin: 0 auto;
  text-align: center;
  margin-top: 6.4vh;
  height: 110px;
  color: #166e71;
  cursor:pointer;
  font-size: 20px;
}
.search_more img{
  display: block;
  margin: 0 auto;
  width:25px;
  margin-top: 10px;
}


.swiper-slide {
    border-radius: 10px;
    margin-top: 30px;
    // margin-left: 30px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 450px;
    overflow: hidden;
    .swiper-slide-a {
      display: flex;
      width: 100%;
      height: 100%;
      .swiper-slide-bg {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .image {
            flex: 1;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
       
        .swiper-slide-content {
            width: 100%;
            // background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
            padding: 20px;
            .organization {
                margin-top: 15px;
                display: flex;
                width: 100vw;
                height: 50px;
                margin-bottom: 10px;
            }
            .organ > p {
                font-size: 12px;
                text-align: left;
                padding-left: 10px;
                color: #848887;
                padding-top: 5px;
            }
            .organ > span {
                padding-top: 3px;
                display: block;
                padding-left: 10px;
                font-size: 18px;
                text-align: left;
                color: #606060;
            }
            .title {
                width: 100%;
                text-align: left;
                padding-left: 0;
            }
            .title a {
                display: block;
                color: #18716e;
                // text-decoration: none;
                letter-spacing: 1.5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
            }
            .title p {
                font-size: 12px;
                padding-top: 20px;
                color: #606060;
            }
            .progress-container {
                width: 100px;
                height: 5px;
                background-color: #76787a;
                border-radius: 15px;
                margin-left: 0;
                margin-top: 6px;
            }
            .progress {
                height: 5px;
                border-radius: 15px;
                background-color: #fe6b6b;
                width: var(--percent);
            }
            .people_number {
                float: left;
                font-size: 12px;
                margin-left: 0;
                margin-top: 7px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                p {
                color: #606060;
                }
            }
        }
      }
    }
  }
</style>
