<template>
    <div>
        <swiper />
    </div>
</template>

<script>
import swiper from './conponents/swiper.vue'
export default {
    components:{swiper}
}
</script>


<style scoped>

</style>