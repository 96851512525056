<template>
  <div style="background: #ecf3f3">
    <div class="swiper-container" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" v-loading="loading"  element-loading-text="加載内容中">
      <div class="swiper-wrapper" v-if="fundraises.length > 0">
        <div class="swiper-slide" v-for="fundraise in fundraises" :key="fundraise">
          <router-link class="swiper-slide-a" :to="{name:'project',params: { id: fundraise.uuid }}">
            <div class="swiper-slide-bg" :style="{backgroundImage: `url(${fileUrl(fundraise.project.image)})`}">
              <div class="swiper-slide-content">
                <div class="organization">
                  <img
                    :src="fileUrl(fundraise.agency.avatar)"
                    style="width: 50px"
                  />
                  <div class="organ">
                    <span>{{fundraise.agency.name}}</span>
                    <p>已發起{{fundraise.agency.fundraisesCount}}個項目 · {{fundraise.agency.totalNumberDonations}}個支持者</p>
                  </div>
                </div>
                <div class="title">
                  <p class="title_p">{{ fundraise.project.title }}</p>
                  <p>已籌 {{ fundraise.people_raised }}/{{ fundraise.total_raised }}</p>
                </div>
                <div class="progress-container">
                  <div class="progress" :style="{ '--percent': fundraise.percentage }"></div>
                </div>
                <div class="people_number">
                  <p>捐款人數: {{ fundraise.people_number }}</p>
                  <p v-if="fundraise.countDown">{{ fundraise.countDown }}</p>
                  <p v-else>恆常項目</p>
                </div>
              </div>
              
            </div>
          
          
          </router-link>
        </div>
      </div>
      <div class="swiper-wrapper" v-else>
          <p style="color:red;text-align:center;width:100%;height:100%;line-height:400px;font-size:35px;">{{fundraises_title}}</p>
      </div>
      <!-- 小圆点-->
      <div class="swiper-pagination"></div>
    </div>
    <div class="bottom" id="aa">
      <div class="donate">
        <div>
          <p style="color: #166e71;">未有心水的捐款項目？</p>
          <span style="color: #166e71;">直接捐款至分類，為服務對象出一份力</span>
        </div>
        
        <router-link to="/donate/0/1">立即捐款</router-link>
      </div>
    </div>

    <!-- <div class="search">
        <input placeholder="探索" v-model="explore" />
        <img v-on:click="explores()" style="cursor:pointer;" src="../../../assets/img/search.png" />
      </div> -->
    <div class="search_more" v-on:click="f1">
      <!-- <p style="padding-top:2vh;">
        搜索更多
      <img src="../../../assets/img/downarrow.png">
      </p> -->
    </div>
    <fundraisesAll v-show="search"/>
  </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper";
import fundraisesAll from "./fundraisesAll";
export default {
  data() {
    return {
      explore:"",
      search:false,
      loading:false,
      title: "標題標題標題標題標題標題標題標題標題標題標題標題標",
      people_raised: 8000,
      total_raised: 10000,
      people_number: 50,
      nowDate: "",
      DeadLine: "2021/6/30",
      fundraises: {},
      state: 50010,
      pageSize:3,
      fundraises_title:'',
      category_title:'',
      // percentage: "90px",
    };
  },
  components:{
    fundraisesAll,
  },
  computed: {
    searchs: function (){
      return this.$store.state.search;
    },
    load: function (){
      return this.load;
    },
  },
  watch:{
    searchs(){
      this.search = this.$store.state.search;
    },
  },
  methods: {
    percentage: function (data) {
      return parseInt((100 * data.people_raised) / data.total_raised) <= 100 ? parseInt((100 * data.people_raised) / data.total_raised) + "px" : '100px';
    },
    fileUrl(file) {
      return process.env.VUE_APP_SERVER +file;
    },
    countDown(val){
        var dates = new Date();
        var now = dates.getTime();
        var leftTime = val - now;
            var d,h,m,s;
         if (leftTime>=0) {
               d = Math.floor(leftTime/1000/60/60/24);
             h = Math.floor(leftTime/1000/60/60%24);
             m = Math.floor(leftTime/1000/60%60);
             s = Math.floor(leftTime/1000%60);
         }
         //将倒计时赋值到div中
         if(d === 0){
           return h+"小時";
         }else if(h === 0){
           return m+'分鐘';
         }else if(m === 0){
           return s+'秒';
         }else{
           return d+"天";
         }
    },
    f1(){
      // 点击搜索更多 给monitor_search值进行增加 这样就能实时监听
      if(this.$store.state.search === false){
          this.$store.state.monitor_search += 1;
      }
      this.$store.state.search = true;
    },
    // 填寫探索内容 篩選出與該内容所匹配的所有類型
    explores(){
      this.$store.state.search_page_id = -1;
      if(this.explore){
        this.$store.state.keyword = this.explore;
      }else{
        this.$store.state.keyword = "keyword123456789";
      }
    },
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      this.nowDate = `${year}/${month}/${day}`;
    },
  },
  created: function () {
    this.$store.state.search = false;
    this.loading = true;

    api.fundraise.getFundraises("?pageNum=1&pageSize="+this.pageSize+"&state="+this.state).then(response => {
      let data = response.data.results;
      if(response.data.count === 0){
        this.fundraises_title = '沒有衆籌項目！';
      }
      for(let i=0; i<data.length; i++){
          let constant_project = data[i].project.constantProject;
          if(constant_project == 'N'){
            let estimatedStartTime  = data[i].project.estimatedStartTime;
            let projectTime = data[i].project.projectTime;
            var date = new Date(estimatedStartTime);
            var time =Date.parse(new Date());
            date = date.setDate(date.getDate() + projectTime);
            if(time >= date){
              data[i].countDown = '已完結';
            }else{
              data[i].countDown =  this.countDown(date);
            }
          }else{
            data[i].countDown = '';
          }

          data[i].people_raised =data[i].fundraiseAmount;
          data[i].total_raised =data[i].amountRequired;
          data[i].percentage =this.percentage(data[i]);
          data[i].people_number =data[i].numberDonations;
      }
      this.fundraises = response.data.results;
      this.loading = false;
    }).catch(error => {
      this.$message.error('衆籌項目數據獲取異常，請重新刷新獲取');
      this.fundraises_title = '衆籌項目數據獲取異常，請重新刷新獲取衆籌數據！';
      this.loading = false;
      this.explore = false;
    });
    this.search = this.$store.state.search;

    this.f1();
  },
  updated() {
    this.currentTime();
    var mySwiper = new Swiper(".swiper-container", {
      // loop: true,
      // loopedSlides: 3,
      spaceBetween: 30,
      slidesPerView: 3,
      autoplay: {
        delay: 3000,
      },
      /*clickable :true,点击分页器的指示点分页器会控制Swiper切换。 */
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    /* 鼠标滑过pagination控制swiper切换*/
    for (let i = 0; i < mySwiper.pagination.bullets.length; i++) {
      mySwiper.pagination.bullets[i].onmouseover = function () {
        this.click();
      };
      //如果你在swiper初始化后才决定使用clickable，可以这样设置
      mySwiper.params.pagination.clickable = true;
      //此外还需要重新初始化pagination
      mySwiper.pagination.destroy();
      mySwiper.pagination.init();
      mySwiper.pagination.bullets
        .eq(0)
        .addClass("swiper-pagination-bullet-active");
    }
  },
};
</script>
<style lang="scss" scoped>
.router-link-exact-active{
  text-align: center;
}
#active{
  color:red;
}
.swiper-container {
  width: 80%;
  background-color: #ebf4f3;
  // height: 440px;
  margin-top:80px;
  .swiper-pagination {
    position: relative;
    bottom: 0;
    left: 0;
  }
}
/* .content{
    width: 100%;
    height: 150px;
    background-color: green;
} */
.organization {
  margin-top: 15px;
  display: flex;
  width: 100vw;
  height: 50px;
  margin-bottom: 10px;
}
.swiper-slide {
    border-radius: 10px;
    margin-top: 30px;
    // margin-left: 40px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 450px;
    overflow: hidden;
    .swiper-slide-a {
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
      .swiper-slide-bg {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: flex-end;
        .swiper-slide-content {
          width: 100%;
          background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
          padding: 20px;
          .organ > p {
            font-size: 12px;
            text-align: left;
            padding-left: 10px;
            color: #fff;
            padding-top: 5px;
          }
          .organ > span {
            padding-top: 3px;
            display: block;
            padding-left: 10px;
            font-size: 18px;
            text-align: left;
            color: #fff;
          }
          .title {
            width: 100%;
            text-align: left;
            padding-left: 0;
          }
          
          .title p {
            font-size: 12px;
            padding-top: 20px;
            color: #fff;
          }
          .title .title_p {
            display: block;
            color: #fff;
            text-decoration: none !important;
            letter-spacing: 1.5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            font-size: 20px;
          }
          .progress-container {
            width: 100px;
            height: 5px;
            background-color: #fff;
            border-radius: 15px;
            margin-left: 0;
            margin-top: 6px;
          }
          .progress {
            height: 5px;
            border-radius: 15px;
            background-color: #fe6b6b;
            width: var(--percent);
          }
          .people_number {
            float: left;
            font-size: 12px;
            margin-left: 0;
            margin-top: 7px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            p {
              color: #fff;
            }
          }
        }
      }
    }
  }
.organ {
  height: 50px;
  font-size: 12px;
}
.organ > p {
  font-size: 12px;
  text-align: left;
  padding-left: 10px;
  color: #848887;
  padding-top: 5px;
}
.organ > span {
  padding-top: 3px;
  display: block;
  padding-left: 10px;
  font-size: 18px;
  text-align: left;
  color: #606060;
}
.title {
  width: 90%;
  text-align: left;
  padding-left: 10px;
}
.title a {
  color: #18716e;
  text-decoration: none;
  letter-spacing: 1.5px;
}
.title p {
  font-size: 12px;
  padding-top: 20px;
}
.progress-container {
  width: 100px;
  height: 5px;
  background-color: #76787a;
  border-radius: 15px;
  margin-left: 10px;
  margin-top: 6px;
}
.progress {
  height: 5px;
  border-radius: 15px;
  background-color: #fe6b6b;
  width: var(--percent);
}
.people_number {
  float: left;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 7px;
  display: flex;
  width: 95%;
  justify-content: space-between;
}
.bottom {
  margin: 0 auto;
  width: 80%;
  /* height: 200px; */
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.donate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // height: 170px;
  background-color: #ffffff;
  text-align: left;
  color: #166e71;
  box-shadow: 4px 4px 8px 0px #d3d7d880;
  padding: 40px;
  border-radius: 10px;
}
.donate p {
  font-size: 20px;
  // margin:10px;
  margin-bottom: 20px;
}
.donate span {
  display: block;
  font-size: 38px;
  font-weight: bold;
  font-family: "黑体";
  margin-top: 15px;
}
.donate a {
  display: block;
  width: 160px;
  height: 35px;
  background: #ff6a6d;
  text-align: center;
  line-height: 35px;
  border-radius: 10px;
  font-size: 16px;
  color: white;
  font-family: "黑体";
  margin-left: 20px;
  // margin-top: 15px;
  text-decoration: none;
}
.search {
  width: 450px;
  /* height: 170px; */
  background-color: #fff;
  box-shadow: 4px 4px 4px 4px #d3d7d8;
  text-align: center;
}
.search input {
  margin: 0 auto;
  font-size: 25px;
  height: 35px;
  margin-top: 30px;
  width: 300px;
  background: none;
  border: none;
  /* 重点是outline: none; */
  outline: none;
  width:90%;
  padding-left:5px;
  border-bottom: 1px solid #14716d;
}
input::-webkit-input-placeholder {
  color: #2d867f;
}
.search > img {
  display: inline-block;
  margin-left: -10px;
}

</style>